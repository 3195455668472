<template>
  <v-card width="100%">
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso de actualización de adicionales</v-card-title
      >
      <v-data-table
        :headers="headers"
        :items="detallesAumentoAdiExtra"
        class="elevation-1"
        :loading="isLoading"
      >
        <template v-slot:[`item.tieneIva`]="{ item }">
          <v-icon small color="primary">
            {{ item.tieneIva ? checkIcon : "" }}
          </v-icon>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="p-2">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "VerDetalleActualizacionAdiExtra",
  props: {
    aumentoId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    checkIcon: enums.icons.CHECK_OUTLINE,
    excelIcon: enums.icons.EXPORT_EXCEL,
    isLoading: false,
    detallesAumentoAdiExtra: [],
    headers: [
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        sortable: false
      },
      {
        text: "Tipo de comprobante",
        value: "comprobante",
        sortable: false
      },
      {
        text: "Concepto",
        value: "concepto",
        sortable: false
      },
      {
        text: "Forma de pago",
        value: "formaPago",
        sortable: false
      },
      {
        text: "Importe original",
        value: "importeOriginal",
        align:"end",
        sortable: false
      },
      {
        text: "Importe actualizado",
        value: "importeActualizado",
        align:"end",
        sortable: false
      },
      {
        text: "Tiene IVA",
        value: "tieneIva",
        align:"center",
        sortable: false
      },
      {
        text: "Vigencia original",
        value: "vigenciaOriginal",
        align:"center",
        sortable: false
      },
      {
        text: "Vigencia actualizada",
        value: "vigenciaActualizada",
        align:"center",
        sortable: false
      }
    ],
    allowedActions: null
  }),
  created() {
    this.getDetallesAumentoAdiExtra();
  },
  methods: {
    ...mapActions({
      verDetalleAumentoAdiExtra: "devengamientos/verDetalleAumentoAdiExtra"
    }),
    async getDetallesAumentoAdiExtra() {
      this.isLoading = true;
      const data = await this.verDetalleAumentoAdiExtra({
        aumentoId: this.aumentoId
      });
      this.detallesAumentoAdiExtra = data;
      this.isLoading = false;
    },

    closeModalVerDetalle() {
      this.$emit("toggleModalSeeProcesoActualizacion");
    },
    exportExcelModelo() {
      let result = [];
      this.detallesAumentoAdiExtra.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agectaNom,
          ["Comprobante"]: x.comprobante,
          ["Concepto"]: x.concepto,
          ["Forma de pago"]: x.formaPago,
          ["Importe original"]: x.importeOriginal,
          ["Importe actualizado"]: x.importeActualizado,
          ["Tiene IVA"]: x.tieneIva ? "SI" : "NO",
          ["Vigencia original"]: x.vigenciaOriginal,
          ["Vigencia actualizada"]: x.vigenciaActualizada
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalles de actualización"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle de actualización"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}</style>
