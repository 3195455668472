<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-1" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-model="isFormValid"
                v-show="showFilters"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Fecha desde -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="fechaDesde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedFechaDesdeFormatted"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(
                              computedFechaDesdeFormatted
                            )
                          "
                          :rules="
                            (computedFechaDesdeFormatted &&
                              computedFechaHastaFormatted) != null
                              ? [
                                  new Date(
                                    parseDateToIso(computedFechaDesdeFormatted)
                                  ) <=
                                    new Date(
                                      parseDateToIso(
                                        computedFechaHastaFormatted
                                      )
                                    ) || 'Rango de fechas inválido'
                                ]
                              : []
                          "
                          dense
                          outlined
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        @change="
                          computedFechaDesdeFormatted = formatDate(fechaDesde)
                        "
                        no-title
                        scrollable
                        @input="menuFechaDesde = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="fechaHasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedFechaHastaFormatted"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(
                              computedFechaHastaFormatted
                            )
                          "
                          :rules="
                            (computedFechaDesdeFormatted &&
                              computedFechaHastaFormatted) != null
                              ? [
                                  new Date(
                                    parseDateToIso(computedFechaDesdeFormatted)
                                  ) <=
                                    new Date(
                                      parseDateToIso(
                                        computedFechaHastaFormatted
                                      )
                                    ) || 'Rango de fechas inválido'
                                ]
                              : []
                          "
                          dense
                          outlined
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        @change="
                          computedFechaHastaFormatted = formatDate(fechaHasta)
                        "
                        no-title
                        scrollable
                        @input="menuFechaHasta = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="procesosActualizacion"
            class="elevation-1"
            :search="search"
            :loading="loading"
            show-expand
            :expanded.sync="expanded"
            item-key="devAdiExtraAumentoId"
          >
            <template v-slot:[`item.anulado`]="{ item }">
              <v-icon small color="primary">
                {{ item.fechaAnula ? checkIcon : "" }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" align="end">
                    <v-btn
                      v-if="puedeCrearProceso"
                      color="primary"
                      class="to-right"
                      @click="goToNuevoProcesoActualizacion()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    @click="handleExpansion(item, isExpanded)"
                    v-on="on"
                  >
                    {{ isExpanded ? "mdi-account-check" : "mdi-account" }}
                  </v-icon>
                </template>
                <span>Ver usuarios y fechas</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    :disabled="item.fechaAnula != null"
                    size="20"
                    v-on="on"
                    @click="
                      toggleModalSeeProcesoActualizacion(
                        item.devAdiExtraAumentoId
                      )
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
              <v-tooltip left v-if="puedeAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.fechaAnula != null"
                    @click="anularProceso(item.devAdiExtraAumentoId)"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="3">
                    <strong>Usuario alta: </strong>
                    {{ item.usuAlta }}
                  </v-col>
                  <v-col cols="12" md="3"
                    ><strong>Fecha alta: </strong> {{ item.fechaAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario anula: </strong>
                    {{
                      item.usuAnula != null ? item.usuAnula + "." : "Sin datos."
                    }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Fecha anula: </strong>
                    {{ item.usuAnula != null ? item.fechaAnula : "Sin datos." }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmAnularProceso()"
    />
    <v-dialog
      v-if="modalVerDetalleActualizacionAdiExtra"
      v-model="modalVerDetalleActualizacionAdiExtra"
      max-width="85%"
      @keydown.esc="toggleModalSeeProcesoActualizacion"
      persistent
    >
      <VerDetalleActualizacionAdiExtra
        :aumentoId="aumentoId"
        @toggleModalSeeProcesoActualizacion="toggleModalSeeProcesoActualizacion"
      ></VerDetalleActualizacionAdiExtra
    ></v-dialog>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import VerDetalleActualizacionAdiExtra from "@/components/modules/cuotas/devengamientos/VerDetalleActualizacionAdiExtra.vue";
import Ayuda from "@/components/shared/Ayuda.vue";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "ProcesoActualizacionAdicionales",
  directives: { mask },
  components: {
    PageHeader,
    Ayuda,
    FiltersSelected,
    DeleteDialog,
    GoBackBtn,
    VerDetalleActualizacionAdiExtra
  },

  data: vm => ({
    routeToGo: "AdicionalesExtra",
    title: enums.titles.ACTUALIZACION_ADICIONALES_EXTRA,
    search: "",
    titleDelete: "¿Desea anular el proceso?",
    expanded: [],
    allowedActions: null,
    rules: rules,
    showFilters: false,
    filtersApplied: [],
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.ACTUALIZACION_ADICIONALES_EXTRA,
    showIcon: true,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    userIcon: enums.icons.GROUP,
    seeIcon: enums.icons.SEE,
    errorIcon: enums.icons.CLOSE,
    anularIcon: enums.icons.CIRCLE_NONE,
    isFormValid: true,
    loading: false,
    calendarIcon: enums.icons.CALENDAR,
    procesosActualizacion: [],
    headers: [
      {
        text: "N° proceso",
        align: "start",
        value: "devAdiExtraAumentoId",
        sortable: false
      },
      {
        text: "Tipo de comprobante",
        align: "start",
        value: "tCompNom",
        sortable: false
      },
      {
        text: "Concepto",
        align: "start",
        value: "conceptoNom",
        sortable: false
      },
      {
        text: "Forma de pago",
        align: "start",
        value: "fPagoNom",
        sortable: false
      },
      {
        text: "Vigencia",
        align: "start",
        value: "vigencia",
        sortable: false
      },
      {
        text: "Importe desde",
        align: "end",
        value: "importeDesdeString",
        sortable: false
      },
      {
        text: "Importe hasta",
        align: "end",
        value: "importeHastaString",
        sortable: false
      },
      {
        text: "Anulado",
        align: "center",
        value: "anulado",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", align: "end", value: "data-table-expand" }
    ],
    showDeleteModal: false,
    menuFechaHasta: null,
    computedFechaHastaFormatted: null,
    fechaHasta: null,
    menuFechaDesde: null,
    computedFechaDesdeFormatted: null,
    fechaDesde: null,
    modalVerDetalleActualizacionAdiExtra: false,
    aumentoId: null,
    idToDelete: null,
    puedeCrearProceso: false,
    puedeAnular: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getFechas();
    this.applyFilters();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      consultaAumentoAdicionales: "devengamientos/consultaAumentoAdicionales",
      anulaAumentoAdicionales: "devengamientos/anulaAumentoAdicionales",
      setAlert: "user/setAlert"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_ACTUALIZACION_ADICIONALES:
            this.puedeCrearProceso = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_PROCESO_ACTUALIZACION_ADICIONALES:
            this.puedeAnular = true;
            break;
          default:
            break;
        }
      });
    },
    goToNuevoProcesoActualizacion() {
      this.$router.push({
        name: "NuevoProcesoActualizacionAdicionales"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    toggleModalSeeProcesoActualizacion(id) {
      this.modalVerDetalleActualizacionAdiExtra = !this
        .modalVerDetalleActualizacionAdiExtra;
      this.aumentoId = id;
    },
    anularProceso(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmAnularProceso() {
      const response = await this.anulaAumentoAdicionales({
        devAdiExtraAumentoId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Anulado con éxito." });
        this.applyFilters();
      }
    },

    async applyFilters() {
      this.loading = true;
      this.toggleFiltersSelected();
      const data = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta
      };
      try {
        const procesos = await this.consultaAumentoAdicionales(data);
        this.procesosActualizacion = procesos;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let one = new Date(now.setMonth(now.getMonth() - 1));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      let oneDia = one.getDate();
      oneDia < 10 ? (oneDia = `0${oneDia}`) : oneDia;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      this.fechaDesde = `${oneAño}-${oneMes}-${oneDia}`;
      this.computedFechaDesdeFormatted = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.computedFechaHastaFormatted = this.formatDate(this.fechaHasta);
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];

      if (this.computedFechaDesdeFormatted) {
        this.filtersApplied.splice(0, 1, {
          key: "computedFechaDesdeFormatted",
          label: "Fecha desde",
          model: this.computedFechaDesdeFormatted
        });
      }
      if (this.computedFechaHastaFormatted) {
        this.filtersApplied.splice(1, 1, {
          key: "computedFechaHastaFormatted",
          label: "Fecha hasta",
          model: this.computedFechaHastaFormatted
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
